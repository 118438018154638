import React, { Dispatch, SetStateAction } from "react"

import { RequestCallModal } from "../../../components/_V2/RequestCallModal/RequestCallModal"
import styles from "../../../components/_V2/RequestCallModal/styles.module.scss"

import data from "./data"

interface Props {
	className?: string
	isOpen: boolean
	onClose: Dispatch<SetStateAction<boolean>>
	request?: Function
}

export default function DesignCtaForm(props: Props) {
	return (
		<>
			<RequestCallModal
				isOpen={props.isOpen}
				onClose={props.onClose}
				modalData={data.modal}
				inputTitle={data.inputTitle}
				nameTitle={data.nameTitle}
				withName={data.withName}
				showBackdropShape={true}
				className={styles.contentV2}
				request={props.request}
			/>
		</>
	)
}
