import * as React from "react"
import { SVGProps } from "react"

const Backdrop = (props: SVGProps<SVGSVGElement>) => (
	<svg
		{...props}
		width="795"
		height="515"
		viewBox="0 0 795 515"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
	>
		<path
			opacity="0.3"
			fillRule="evenodd"
			clipRule="evenodd"
			d="M282 141.001C282 147.141 287.88 151.884 293.855 150.468C307.565 147.218 321.866 145.499 336.57 145.499C357.413 145.499 377.449 148.955 396.136 155.326C427.437 165.997 473.158 149.591 490.122 121.205C523.591 65.2017 584.817 27.7012 654.801 27.7012C760.678 27.7012 846.509 113.532 846.509 219.409C846.509 325.286 760.678 411.116 654.801 411.116C630.743 411.116 607.72 406.685 586.504 398.594C555.607 386.81 509.566 402.293 491.609 430.059C458.748 480.872 401.586 514.501 336.57 514.501C234.672 514.501 152.068 431.897 152.068 330C152.068 319.151 153.005 308.521 154.801 298.187C156.245 289.876 149.435 282.001 141 282.001C63.1277 282.001 0 218.873 0 141.001C0 63.1291 63.1277 0.00138855 141 0.00138855C218.872 0.00138855 282 63.1291 282 141.001Z"
			fill="url(#pattern0)"
		/>
		<defs>
			<pattern
				id="pattern0"
				patternContentUnits="objectBoundingBox"
				width="0.0236265"
				height="0.0398445"
			>
				<use
					xlinkHref="#image0_7786_152424"
					transform="scale(0.000590661 0.000971817)"
				/>
			</pattern>
			<image
				id="image0_7786_152424"
				width="40"
				height="41"
				xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAApCAYAAABHomvIAAAAe0lEQVRYCe3SwQ2AIBBEUdwO3Ya0A2yLjL1QAobIBU+cCImfZG5sGB6EwEIAAQQQQAABBBBAAIFFBMx1mCu3xEVqvTXMFc1V+qRzmZJN7VNQmYKjAubp7J9Xxfb7Gp2fsq8W2ly5xlzr/L8pt+cQBBBAAAEEEEAAgV8KPMJGP4Bm2oVZAAAAAElFTkSuQmCC"
			/>
		</defs>
	</svg>
)

export default Backdrop
