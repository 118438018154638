import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import SegmentationBackdrop1 from "./assets/SegmentationBackdrop-1"
import SegmentationBackdrop2 from "./assets/SegmentationBackdrop-2"

import { externalLinks, pagesLinks } from "../../../pages-data/_V2/common/links"
import styles from "./styles.module.scss"
import { Locales } from "../../../localization/types"

export const getFeaturedData = (locale: Locales) => {
	if (locale === "ru-KZ") {
		return [
			{
				category: "подключение",
				title: "Проще некуда",
				contentClass: styles.desk,
				description: (
					<>
						Карты создаются системными приложениями: <br className={styles.connectContent__kz_desktop} />
						Apple Wallet и&nbsp;Google Pay. Все данные гостей <br className={styles.connectContent__kz_desktop} />
						синхронизируются <br className={styles.connectContent__kz_tablet} /> с&nbsp;CRM системой Quick Resto.
					</>
				),
				mediaContent: (
					<div className={styles.connectContent}>
						<figure>
							<StaticImage
								className={styles.connectImage}
								src="./assets/connect_kz.png"
								alt="получение карты лояльности"
								breakpoints={[280, 480]}
								sizes="(max-width: 500px) 280,  (min-width: 501px) 480"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</figure>

						<div className={styles.connectQr}>
							<StaticImage
								className={styles.connectQr__image}
								src="./assets/connect-qr.png"
								alt="получение карты лояльности QR"
								placeholder="blurred"
								quality={90}
							/>

							<span className={styles.connectQr__label}>Сканируй!</span>
						</div>
					</div>
				),

				className: styles.connectFeature,
				theme: "green",
				actionText: "Получить карту",
				actionLink: externalLinks.demoWl.href,
				actionClass: styles.connectFeature__btn,

				// backdrop: <ConnectBackdrop className={styles.connectBackdrop} />,
			},
			{
				category: "сегментация",
				title: "Принимай \nверные решения",
				description:
					"<p>Интеграция с CRM позволяет сегментировать базу клиентов. Работай с гостями эффективнее.</p>",

				img: (
					<StaticImage
						className={styles.segmentationImage}
						src="./assets/segmentation.png"
						alt="CRM-система для кафе"
						breakpoints={[420, 740, 920, 640]}
						sizes="(max-width: 500px) 420px, (max-width: 800px) 740px, (max-width: 1319px) 920px, (min-width: 1320px) 640px"
						objectFit="cover"
						placeholder="blurred"
						quality={90}
					/>
				),

				reverse: true,
				className: styles.segmentationFeature,
				theme: "orange",
				actionText: "Подробнее",
				actionLink: pagesLinks.marketing.href,

				backdrop: (
					<>
						<SegmentationBackdrop1 className={styles.segmentationBackdrop1} />
						<SegmentationBackdrop2 className={styles.segmentationBackdrop2} />
					</>
				),
			},
			{
				category: "Аналитика",
				title: "Знай, что приносит прибыль",
				description:
					"Аналитика по истории заказов покажет, какие механики системы лояльности приносят прибыль бизнесу.</p>",
				img: (
					<StaticImage
						className={styles.analyticsImage}
						src="./assets/analytics_kz.png"
						alt="система лояльности для ресторана"
						breakpoints={[440, 600, 710, 630]}
						sizes="(max-width: 500px) 440px, (max-width: 800px) 600px, (max-width: 1319px) 710px, (min-width: 1320px) 630px"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				className: styles.analyticsFeature,
				theme: "cyan",
				actionText: "Подробнее",
				actionLink: pagesLinks.reports.href,
			},
		]
	}

	if (locale === "kz-KZ") {
		return [
			{
				category: "қосылу",
				title: "Оп-оңай",
				contentClass: styles.desk,
				description: (
					<>
						Карталарды Apple Wallet және Google Pay жүйелік қосымшалары жасайды.
						Қонақтардың барлық деректері Quick Resto CRM жүйесімен синхрондалады.
					</>
				),
				mediaContent: (
					<div className={styles.connectContent}>
						<figure>
							<StaticImage
								className={styles.connectImage}
								src="./assets/connect_kz.png"
								alt="получение карты лояльности"
								breakpoints={[280, 480]}
								sizes="(max-width: 500px) 280,  (min-width: 501px) 480"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</figure>

						<div className={styles.connectQr}>
							<StaticImage
								className={styles.connectQr__image}
								src="./assets/connect-qr.png"
								alt="получение карты лояльности QR"
								placeholder="blurred"
								quality={90}
							/>

							<span className={styles.connectQr__label}>Сканерлеңіз!</span>
						</div>
					</div>
				),

				className: styles.connectFeature,
				theme: "green",
				actionText: "Получить карту",
				actionLink: externalLinks.demoWl.href,
				actionClass: styles.connectFeature__btn,

				// backdrop: <ConnectBackdrop className={styles.connectBackdrop} />,
			},
			{
				category: "сегменттеу",
				title: "Дұрыс шешімдер\nқабылдаңыз",
				description:
					"<p>CRM-мен бірігу клиенттер қорын сегменттеуге мүмкіндік береді. Қонақтармен тиімдірек жұмыс істеңіз.</p>",

				img: (
					<StaticImage
						className={styles.segmentationImage}
						src="./assets/segmentation.png"
						alt="CRM-система для кафе"
						breakpoints={[420, 740, 920, 640]}
						sizes="(max-width: 500px) 420px, (max-width: 800px) 740px, (max-width: 1319px) 920px, (min-width: 1320px) 640px"
						objectFit="cover"
						placeholder="blurred"
						quality={90}
					/>
				),

				reverse: true,
				className: styles.segmentationFeature,
				theme: "orange",
				actionText: "Толығырақ",
				actionLink: pagesLinks.marketing.href,

				backdrop: (
					<>
						<SegmentationBackdrop1 className={styles.segmentationBackdrop1} />
						<SegmentationBackdrop2 className={styles.segmentationBackdrop2} />
					</>
				),
			},
			{
				category: "Аналитика",
				title: "Пайданы не әкелетінін біліңіз",
				description:
					"Тапсырыстар тарихы бойынша аналитика адалдық жүйесінің қандай механикалары бизнеске пайда әкелетінін көрсетеді.</p>",
				img: (
					<StaticImage
						className={styles.analyticsImage}
						src="./assets/analytics_kz.png"
						alt="система лояльности для ресторана"
						breakpoints={[440, 600, 710, 630]}
						sizes="(max-width: 500px) 440px, (max-width: 800px) 600px, (max-width: 1319px) 710px, (min-width: 1320px) 630px"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				className: styles.analyticsFeature,
				theme: "cyan",
				actionText: "Толығырақ",
				actionLink: pagesLinks.reports.href,
			},
		]
	}

	if (locale === "ru-BY") {
		return [
			{
				category: "подключение",
				title: "Супер-просто",
				contentClass: styles.desk,
				description: (
					<>
						Карты создаются системными приложениями: <br className={styles.connectContent__kz_desktop} />
						Apple Wallet и&nbsp;Google Pay. Все данные гостей <br className={styles.connectContent__kz_desktop} />
						синхронизируются <br className={styles.connectContent__kz_tablet} /> с&nbsp;CRM системой Quick Resto.
					</>
				),
				mediaContent: (
					<div className={styles.connectContent}>
						<figure>
							<StaticImage
								className={styles.connectImage}
								src="./assets/connect_by.png"
								alt="получение карты лояльности"
								breakpoints={[280, 480]}
								sizes="(max-width: 500px) 280,  (min-width: 501px) 480"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</figure>

						<div className={styles.connectQr}>
							<StaticImage
								className={styles.connectQr__image}
								src="./assets/connect-qr.png"
								alt="получение карты лояльности QR"
								placeholder="blurred"
								quality={90}
							/>

							<span className={styles.connectQr__label}>Сканируй</span>
						</div>
					</div>
				),

				className: styles.connectFeature,
				theme: "green",
				actionText: "Получить карту",
				actionLink: externalLinks.demoWl.href,
				actionClass: styles.connectFeature__btn,

				// backdrop: <ConnectBackdrop className={styles.connectBackdrop} />,
			},
			{
				category: "сегментация",
				title: "Принимай верные решения",
				description:
					"<p>Интеграция с CRM позволяет сегментировать базу клиентов. Работай с гостями эффективнее.</p>",

				img: (
					<StaticImage
						className={styles.segmentationImage}
						src="./assets/segmentation.png"
						alt="CRM-система для кафе"
						breakpoints={[420, 740, 920, 640]}
						sizes="(max-width: 500px) 420px, (max-width: 800px) 740px, (max-width: 1319px) 920px, (min-width: 1320px) 640px"
						objectFit="cover"
						placeholder="blurred"
						quality={90}
					/>
				),

				reverse: true,
				className: styles.segmentationFeature,
				theme: "orange",
				actionText: "Подробнее",
				actionLink: pagesLinks.marketing.href,

				backdrop: (
					<>
						<SegmentationBackdrop1 className={styles.segmentationBackdrop1} />
						<SegmentationBackdrop2 className={styles.segmentationBackdrop2} />
					</>
				),
			},
			{
				category: "Аналитика",
				title: "Узнай, какие акции приносят прибыль",
				description:
					"Аналитика по истории заказов покажет, какие механики системы лояльности приносят прибыль бизнесу.</p>",
				img: (
					<StaticImage
						className={styles.analyticsImage}
						src="./assets/analytics_by.png"
						alt="система лояльности для ресторана"
						breakpoints={[440, 600, 710, 630]}
						sizes="(max-width: 500px) 440px, (max-width: 800px) 600px, (max-width: 1319px) 710px, (min-width: 1320px) 630px"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				className: styles.analyticsFeature,
				theme: "cyan",
				actionText: "Подробнее",
				actionLink: pagesLinks.reports.href,
			},
		]
	}

	return [
		{
			category: "подключение",
			title: "Попробуй — это просто!",
			description: (
				<>
					Карты лояльности для клиентов создаются системными приложениями Apple
					Wallet и&nbsp;Google Pay. Все данные гостей синхронизируются с&nbsp;CRM
					системой Quick Resto.
					< br/>
					В отличие от пластиковых карточек, смартфон всегда под рукой. Гость не теряет свои бонусы, а
					ресторан не тратит время и деньги на заказ новых партий пластика. Виртуальные карты лояльности
					для бизнеса не теряются и не покрываются царапинами.

				</>
			),
			mediaContent: (
				<div className={styles.connectContent}>
					<figure>
						<StaticImage
							className={styles.connectImage}
							src="./assets/connect.png"
							alt="получение карты лояльности"
							breakpoints={[280, 480]}
							sizes="(max-width: 500px) 280,  (min-width: 501px) 480"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					</figure>

					<div className={styles.connectQr}>
						<StaticImage
							className={styles.connectQr__image}
							src="./assets/connect-qr.png"
							alt="получение карты лояльности QR"
							placeholder="blurred"
							quality={90}
						/>

						<span className={styles.connectQr__label}>Сканируй</span>
					</div>
				</div>
			),

			className: styles.connectFeature,
			theme: "green",
			actionText: "Получить карту",
			actionLink: externalLinks.demoWl.href,
			actionClass: styles.connectFeature__btn,

			// backdrop: <ConnectBackdrop className={styles.connectBackdrop} />,
		},
		{
			category: "сегментация",
			title: "Принимай верные решения",
			description:
				"<p>Интеграция с CRM позволяет сегментировать базу клиентов. Работай с гостями эффективнее.</p>",

			img: (
				<StaticImage
					className={styles.segmentationImage}
					src="./assets/segmentation.png"
					alt="CRM-система для кафе"
					breakpoints={[420, 740, 920, 640]}
					sizes="(max-width: 500px) 420px, (max-width: 800px) 740px, (max-width: 1319px) 920px, (min-width: 1320px) 640px"
					objectFit="cover"
					placeholder="blurred"
					quality={90}
				/>
			),

			reverse: true,
			className: styles.segmentationFeature,
			theme: "orange",
			actionText: "Подробнее",
			actionLink: pagesLinks.marketing.href,

			backdrop: (
				<>
					<SegmentationBackdrop1 className={styles.segmentationBackdrop1} />
					<SegmentationBackdrop2 className={styles.segmentationBackdrop2} />
				</>
			),
		},
		{
			category: "Аналитика",
			title: "Узнай, какие акции приносят прибыль",
			description:(
				<>
					Аналитика по&nbsp;истории заказов покажет, какие механизмы системы лояльности нравятся гостям
					и&nbsp;приносят прибыль бизнесу.
					< br />
					< br />
					Не&nbsp;нужно угадывать потребности гостя. Достаточно заказать карты лояльности для клиентов.
					Доступна вся информация: траты гостя, частые покупки, бонусы, статус и&nbsp;контактные данные.
				</>
			),
			img: (
				<StaticImage
					className={styles.analyticsImage}
					src="./assets/analytics.png"
					alt="система лояльности для ресторана"
					breakpoints={[440, 600, 710, 630]}
					sizes="(max-width: 500px) 440px, (max-width: 800px) 600px, (max-width: 1319px) 710px, (min-width: 1320px) 630px"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			className: styles.analyticsFeature,
			theme: "cyan",
			actionText: "Подробнее",
			actionLink: pagesLinks.reports.href,
		},
	]
}
