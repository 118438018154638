import React from 'react'
import classNames from 'classnames'

import DefaultLayout from '../layout/default'

import ProductPresentation from '../pagesSections/passkit/ProductPresentation'

import Advantages from '../components/_V2/Advantages'
import BusinessTypes from '../components/_V2/BusinessTypes'

import KnowledgeBase from '../pagesSections/index/KnowledgeBase'
import CtaForm from '../pagesSections/index/CtaForm'
import AdditionalServices from '../pagesSections/index/AdditionalServices'
import UniqDesign from '../pagesSections/passkit/UniqDesign'
import ConnectFeatures from '../pagesSections/passkit/ConnectFeatures'
import MobileFeatures from '../pagesSections/passkit/MobileFeatures'
import Features from '../pagesSections/passkit/Features'
import ConsultationFeature from '../pagesSections/passkit/ConsultationFeature'
import { getPasskitMeta } from '../pagesSections/passkit/meta'

import { getData } from '../pages-data/_V2/passkit/advantages'
import { businessTypes } from '../pages-data/_V2/common/business-types-compact'
import { getFeaturedData } from '../pagesSections/passkit/Features/data.tsx'

import pageStyles from '../styles/_V2/pagesStyles/styles.module.scss'
import styles from './styles/_V2/passkit.module.scss'
import { useLocalizationContext } from '../localization/useLocalizationContext'
import { getSeoText } from "../pages-data/_V2/passkit/seotext";
import SeoText from "../components/_V2/SeoText";
import SectionLoyaltyCardsFeatures from "../pagesSections/passkit/SectionLoyaltyCardsFeatures"

export default function PagePasskit() {
  const localizationContext = useLocalizationContext()

  const metaInfo = getPasskitMeta(localizationContext.locale)

  return (
    <DefaultLayout
      key={localizationContext.locale}
      title={metaInfo.title}
      metaTags={metaInfo.metaTags}
      linkCanonical={metaInfo.canonical}
      mainWrapperClassName={pageStyles.pageWrapper}
      isHeaderFixed
      footerClassName={pageStyles.pageSection}
      schemaType="Product"
      schemaName={metaInfo.title}
      schemaDescription={metaInfo.metaTags.find((tag) => tag.name === 'description').content}
      schemaImage={metaInfo.metaTags.find((tag) => tag.property === 'og:image').content}
    >
      <ProductPresentation />

      <Advantages
        className={classNames(
				  styles.passkit__advantages,
				  pageStyles.pageSection,
        )}
        items={getData(localizationContext.locale)}
      />

      <ConnectFeatures className={pageStyles.pageSection} />

      <Features
        data={getFeaturedData(localizationContext.locale).filter((obj, idx) => idx === 0)}
        className={pageStyles.pageSection}
      />

      <CtaForm isFry={false} className={pageStyles.pageSection} />

      <Features
        data={getFeaturedData(localizationContext.locale).filter((obj, idx) => idx >= 1)}
        className={pageStyles.pageSection}
      />

      <UniqDesign className={pageStyles.pageSection} />

      <ConsultationFeature className={pageStyles.pageSection} />

      <MobileFeatures className={pageStyles.pageSection} />

      <SectionLoyaltyCardsFeatures className={pageStyles.pageSection} />

      <AdditionalServices className={pageStyles.pageSection} />

      <CtaForm isFry={false} className={pageStyles.pageSection} />

      <BusinessTypes key={localizationContext.locale} data={businessTypes(localizationContext.locale)} className={pageStyles.pageSection} />

      <KnowledgeBase type="horizontal" className={pageStyles.pageSection} />
      <SeoText
          className={pageStyles.pageSection}
          data={getSeoText()}
      />
    </DefaultLayout>
  )
}
