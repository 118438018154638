import React from "react"
import classNames from "classnames"

import styles from "./styles.module.scss"

export type ColorTheme = "fuchsia" | "primary"

export interface TextBillboardProps {
	data: {
		title: string
		description: string | JSX.Element
		majorFigure: JSX.Element
		minorFigure: JSX.Element
		mobileFigure: JSX.Element
		detailLink: string
		detailText: string
	}
	action: Function
	className?: string
}

export default function TextBillboard(props: TextBillboardProps) {
	const majorFigure = props.data.majorFigure
	const minorFigure = props.data.minorFigure
	const mobileFigure = props.data.mobileFigure

	return (
		<div className={classNames(styles.textBillboard, props.className)}>
			{majorFigure && (
				<div className={styles.textBillboardLeftFigure}>{majorFigure}</div>
			)}

			{mobileFigure && (
				<div className={styles.textBillboardMobileFigure}>{mobileFigure}</div>
			)}

			<div className={styles.textBillboardContent}>
				<h2 className={styles.textBillboardTitle}>{props.data.title}</h2>
				<p className={styles.textBillboardDescription}>
					{props.data.description}
				</p>
				<div className={styles.textBillboardActionWrapper}>
					<button
						type={"button"}
						onClick={props.action}
						className={styles.textBillboardAction}
					>
						{props.data.detailText}
					</button>
				</div>
			</div>

			{minorFigure && (
				<div className={styles.textBillboardRightFigure}>{minorFigure}</div>
			)}
		</div>
	)
}
