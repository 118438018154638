import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import OnlineBackdrop from "./assets/OnlineBackdrop"
import OfflineBackdrop from "./assets/OfflineBackdrop"

import { FeatureGalleryItem } from "../../../components/_V2/FeaturesGallery"
import mediaContent from "./mediaContent.module.scss"
import { Locales } from "../../../localization/types"

export const getData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return {
			features: [
				{
					tabName: "Онлайн",
					desktopTitle: "Подключай \nклиентов онлайн",
					desktopContent: (
						<>
							Размести ссылку для оформления карты в&nbsp;соцсетях <br />
							или на&nbsp;сайте. Добавь текст с&nbsp;интересным предложением, <br />
							чтобы привести новых гостей.
						</>
					),
					tabletContent: "Размести ссылку для оформления карты в соцсетях или на сайте. Добавь текст с интересным предложением, \nчтобы привести новых гостей.",
					mobileContent: "Размести ссылку для оформления \nкарты в соцсетях или на сайте. \nДобавь текст с интересным \nпредложением, чтобы привести \nновых гостей.",
					mediaContent: (
						<StaticImage
							className={mediaContent.image1}
							src="./assets/passkit-features-online.png"
							alt={"онлайн карты лояльности"}
							width={440}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <div className={mediaContent.backdrop1} />,
				},
				{
					tabName: "Оффлайн",
					desktopTitle: "Быстрый переход \nк выгоде",
					desktopContent: (
						<>
							Размести ссылку для оформления карты в виде <br />
							QR-кода в своём заведении. Расскажи о выгодах <br />
							системы лояльности для держателей карт.
						</>
					),
					tabletContent: (
						<>
							Размести ссылку для оформления карты в виде QR-кода в своём заведении. Расскажи о выгодах системы <br />
							лояльности для держателей карт.
						</>
					),
					mobileContent: (
						<>
							Размести ссылку для оформления <br />
							карты в виде QR-кода в своём <br />
							заведении. Расскажи о выгодах <br />
							системы лояльности для <br />
							держателей карт.
						</>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image2}
							src="./assets/passkit-features-offline.png"
							alt={"карта лояльности по qr-коду"}
							objectFit={"cover"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <OfflineBackdrop className={mediaContent.backdrop2} />,
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		}
	}

	if (locale === 'kz-KZ') {
		return {
			features: [
				{
					tabName: "Онлайн",
					desktopTitle: "Клиенттерді\nонлайн қосыңыз",
					desktopContent: (
						<>
							Картаны ресімдеуге арналған сілтемені әлеуметтік желілерде немесе сайтта орналастырыңыз.
							Жаңа қонақтарды ертіп келу үшін қызық ұсынысы бар мәтінді қосыңыз.
						</>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image1}
							src="./assets/passkit-features-online.png"
							alt={"онлайн карты лояльности"}
							width={440}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <div className={mediaContent.backdrop1} />,
				},
				{
					tabName: "Оффлайн",
					desktopTitle: "Пайдаға жылдам\nауысу",
					desktopContent: (
						<>
							Өз мекемеңізде QR-код түрінде картаны ресімдеуге арналған сілтемені орналастырыңыз.
							Карталарды ұстаушылар үшін адалдық жүйесінің пайдалары туралы айтыңыз.
						</>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image2}
							src="./assets/passkit-features-offline.png"
							alt={"карта лояльности по qr-коду"}
							objectFit={"cover"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <OfflineBackdrop className={mediaContent.backdrop2} />,
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		}
	}

	if (locale === 'ru-BY') {
		return {
			features: [
				{
					tabName: "Онлайн",
					desktopTitle: "Подключай \nклиентов онлайн",
					desktopContent: (
						<>
							Размести ссылку для оформления карты в&nbsp;соцсетях <br />
							или на&nbsp;сайте. Добавь текст с&nbsp;интересным предложением, <br />
							чтобы привести новых гостей.
						</>
					),
					tabletContent: "Размести ссылку для оформления карты в соцсетях или на сайте. Добавь текст с интересным предложением, \nчтобы привести новых гостей.",
					mobileContent: "Размести ссылку для оформления \nкарты в соцсетях или на сайте. \nДобавь текст с интересным \nпредложением, чтобы привести \nновых гостей.",
					mediaContent: (
						<StaticImage
							className={mediaContent.image1}
							src="./assets/passkit-features-online.png"
							alt={"онлайн карты лояльности"}
							width={440}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <div className={mediaContent.backdrop1} />,
				},
				{
					tabName: "Оффлайн",
					desktopTitle: "Быстрый переход \nк выгоде",
					tabletTitle: "Быстрый переход к выгоде",
					mobileTitle: "Быстрый \nпереход \nк выгоде",
					desktopContent: (
						<>
							Размести ссылку для оформления карты в виде <br />
							QR-кода в своём заведении. Расскажи о выгодах <br />
							системы лояльности для держателей карт.
						</>
					),
					tabletContent: (
						<>
							Размести ссылку для оформления карты в виде QR-кода в своём заведении. Расскажи о выгодах системы <br />
							лояльности для держателей карт.
						</>
					),
					mobileContent: (
						<>
							Размести ссылку для оформления <br />
							карты в виде QR-кода в своём <br />
							заведении. Расскажи о выгодах <br />
							системы лояльности для <br />
							держателей карт.
						</>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image2}
							src="./assets/passkit-features-offline.png"
							alt={"карта лояльности по qr-коду"}
							objectFit={"cover"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <OfflineBackdrop className={mediaContent.backdrop2} />,
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		}
	}

	return {
		features: [
			{
				tabName: "Онлайн",
				desktopTitle: "Подключай клиентов онлайн",
				desktopContent: (
					<>
						Размести ссылку для оформления виртуальной карты лояльности ресторана или кафе
						в&nbsp;соцсетях или на&nbsp;сайте. Добавь текст с&nbsp;интересным
						предложением, чтобы привести новых гостей.
					</>
				),
				mediaContent: (
					<StaticImage
						className={mediaContent.image1}
						src="./assets/passkit-features-online.png"
						alt={"онлайн карты лояльности"}
						width={440}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
				backdropContent: <div className={mediaContent.backdrop1} />,
			},
			{
				tabName: "Оффлайн",
				desktopTitle: "Мгновенный переход",
				desktopContent: (
					<>
						<p>
						Помести QR-код для оформления карты лояльности в&nbsp;кафе. Расскажи
						о&nbsp;выгодах системы для держателей карт.
						</p>
						<p>
						Электронные карты лояльности для бизнеса ускорят сбор клиентской базы и обслуживание.
						Отсканировать электронную карту быстрее, чем искать гостя по номеру телефона или фамилии
						</p>
					</>
				),
				mediaContent: (
					<StaticImage
						className={mediaContent.image2}
						src="./assets/passkit-features-offline.png"
						alt={"карта лояльности по qr-коду"}
						objectFit={"cover"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
				backdropContent: <OfflineBackdrop className={mediaContent.backdrop2} />,
			},
		],
	} as {
		features: Array<FeatureGalleryItem>
	}
}
