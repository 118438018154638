import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import styles from "./styles.module.scss"
import { Locales } from "../../../localization/types"

export const getData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return [
			{
				category: "консультации",
				title: "Забронируй встречу с экспертом",
				description: (
					<p>
						Обучение через разбор кейсов, настройка отчётов для&nbsp;оценки
						рентабельности системы лояльности.
					</p>
				),
				quote: (
					<>
						Не&nbsp;трать время
						<br />
						на&nbsp;исправление ошибок.
						<br />
						Посвяти время бизнесу!
					</>
				),
				img: (
					<StaticImage
						className={styles.consultationImage}
						src="./assets/consultation.png"
						alt="сегментация"
						objectFit="contain"
						objectPosition="top"
						placeholder="blurred"
						quality={90}
					/>
				),

				className: styles.consultationFeature,
				theme: "primary",
				actionText: "Отправить заявку",
				actionLink: "#popup",
				actionClass: styles.consultationAction,
			},
		]
	}

	if (locale === 'kz-KZ') {
		return [
			{
				category: "консультациялар",
				title: "Сарапшымен кездесуді брондаңыз",
				description: (
					<p>
						Кейстерді талдау арқылы оқыту, адалдық жүйесінің табыстылығын бағалау үшін есептерді теңшеу.
					</p>
				),
				quote: (
					<>
						Қателерді түзетуге уақытты<br />
						жұмсамаңыз. Бизнеске <br />
						уақытты бөліңіз!
					</>
				),
				img: (
					<StaticImage
						className={styles.consultationImage}
						src="./assets/consultation.png"
						alt="сегментация"
						objectFit="contain"
						objectPosition="top"
						placeholder="blurred"
						quality={90}
					/>
				),

				className: styles.consultationFeature,
				theme: "primary",
				actionText: "Өтінім жіберу",
				actionLink: "#popup",
				actionClass: styles.consultationAction,
			},
		]
	}

	if (locale === 'ru-BY') {
		return [
			{
				category: "консультации",
				title: "Забронируй \nвстречу с экспертом",
				description: (
					<p>
						Обучение через разбор кейсов, настройка отчётов для&nbsp;оценки
						рентабельности системы лояльности.
					</p>
				),
				quote: (
					<>
						Не&nbsp;трать время
						<br />
						на&nbsp;исправление ошибок.
						<br />
						Посвяти время бизнесу!
					</>
				),
				img: (
					<StaticImage
						className={styles.consultationImage}
						src="./assets/consultation.png"
						alt="сегментация"
						objectFit="contain"
						objectPosition="top"
						placeholder="blurred"
						quality={90}
					/>
				),

				className: styles.consultationFeature,
				theme: "primary",
				actionText: "Отправить заявку",
				actionLink: "#popup",
				actionClass: styles.consultationAction,
			},
		]
	}

	return [
		{
			category: "консультации",
			title: "Забронируй встречу с экспертом",
			description: (
				<p>
					Обучение через разбор кейсов, настройка отчётов для&nbsp;оценки
					рентабельности системы лояльности.
				</p>
			),
			quote: (
				<>
					Не&nbsp;трать время
					<br />
					на&nbsp;исправление ошибок.
					<br />
					Посвяти время бизнесу!
				</>
			),
			img: (
				<StaticImage
					className={styles.consultationImage}
					src="./assets/consultation.png"
					alt="сегментация"
					objectFit="contain"
					objectPosition="top"
					placeholder="blurred"
					quality={90}
				/>
			),

			className: styles.consultationFeature,
			theme: "primary",
			actionText: "Отправить заявку",
			actionLink: "#popup",
			actionClass: styles.consultationAction,
		},
	]
}
