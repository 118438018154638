import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import classNames from "classnames"

import { pagesLinks } from "../../../pages-data/_V2/common/links"

import styles from "./styles.module.scss"
import { Locales } from "../../../localization/types"

export const getData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return {
			title: "Будь уникальным",
			description: (
				<>
					Яркий дизайн с&nbsp;символикой заведения можно создать за&nbsp;пару кликов.
					Мгновенное обновление данных и&nbsp;шаблона карты лояльности.
				</>
			),
			detailLink: `${pagesLinks.passkit}#popup`,
			detailText: "Заказать дизайн",
			majorFigure: (
				<StaticImage
					className={classNames(styles.sectionWlUniqDesignMedia, styles.firstImage)}
					src="./assets/passkit-figure-1.png"
					alt="карта лояльности для ресторана"
					objectFit="cover"
					objectPosition="center -20px"
				/>
			),
			minorFigure: (
				<StaticImage
					className={styles.sectionWlUniqDesignMedia}
					src="./assets/passkit-figure-2.png"
					alt="карта лояльности для ресторана"
					objectFit="cover"
					objectPosition="center 58px"
				/>
			),
			mobileFigure: (
				<StaticImage
					className={styles.sectionWlUniqDesignMobileImg}
					src="./assets/passkit-figure-mobile.png"
					alt="карта лояльности для ресторана"
					objectFit="contain"
				/>
			),
		}
	}

	if (locale === 'kz-KZ') {
		return {
			title: "Бірегей болыңыз",
			description: (
				<>
					Мекеме символикасы бар жарқын дизайнды екі кликпен жасауға болады.
					Деректерді және адалдық картасы шаблонын лезде жаңарту.
				</>
			),
			detailLink: `${pagesLinks.passkit}#popup`,
			detailText: "Дизайнға тапсырыс беру",
			majorFigure: (
				<StaticImage
					className={classNames(styles.sectionWlUniqDesignMedia, styles.firstImage)}
					src="./assets/passkit-figure-1.png"
					alt="карта лояльности для ресторана"
					objectFit="cover"
					objectPosition="center -20px"
				/>
			),
			minorFigure: (
				<StaticImage
					className={styles.sectionWlUniqDesignMedia}
					src="./assets/passkit-figure-2.png"
					alt="карта лояльности для ресторана"
					objectFit="cover"
					objectPosition="center 58px"
				/>
			),
			mobileFigure: (
				<StaticImage
					className={styles.sectionWlUniqDesignMobileImg}
					src="./assets/passkit-figure-mobile.png"
					alt="карта лояльности для ресторана"
					objectFit="contain"
				/>
			),
		}
	}

	if (locale === 'ru-BY') {
		return {
			title: "Стань уникальным",
			description: (
				<>
					Яркий дизайн с&nbsp;символикой заведения можно создать за&nbsp;пару кликов.
					Мгновенное обновление данных и&nbsp;шаблона карты лояльности.
				</>
			),
			detailLink: `${pagesLinks.passkit}#popup`,
			detailText: "Заказать дизайн",
			majorFigure: (
				<StaticImage
					className={classNames(styles.sectionWlUniqDesignMedia, styles.firstImage)}
					src="./assets/passkit-figure-1.png"
					alt="карта лояльности для ресторана"
					objectFit="cover"
					objectPosition="center -20px"
				/>
			),
			minorFigure: (
				<StaticImage
					className={styles.sectionWlUniqDesignMedia}
					src="./assets/passkit-figure-2.png"
					alt="карта лояльности для ресторана"
					objectFit="cover"
					objectPosition="center 58px"
				/>
			),
			mobileFigure: (
				<StaticImage
					className={styles.sectionWlUniqDesignMobileImg}
					src="./assets/passkit-figure-mobile.png"
					alt="карта лояльности для ресторана"
					objectFit="contain"
				/>
			),
		}
	}

	return {
		title: "Будь уникальным",
		description: (
			<>
				Яркий дизайн с&nbsp;символикой заведения&nbsp;&mdash; можно создать
				виртуальные карты лояльности в&nbsp;фирменном дизайне за&nbsp;пару кликов.
				Данные и&nbsp;шаблон обновляются мгновенно.
			</>
		),
		detailLink: `${pagesLinks.passkit}#popup`,
		detailText: "Заказать дизайн",
		majorFigure: (
			<StaticImage
				className={classNames(styles.sectionWlUniqDesignMedia, styles.firstImage)}
				src="./assets/passkit-figure-1.png"
				alt="карта лояльности для ресторана"
				objectFit="cover"
				objectPosition="center -20px"
			/>
		),
		minorFigure: (
			<StaticImage
				className={styles.sectionWlUniqDesignMedia}
				src="./assets/passkit-figure-2.png"
				alt="карта лояльности для ресторана"
				objectFit="cover"
				objectPosition="center 58px"
			/>
		),
		mobileFigure: (
			<StaticImage
				className={styles.sectionWlUniqDesignMobileImg}
				src="./assets/passkit-figure-mobile.png"
				alt="карта лояльности для ресторана"
				objectFit="contain"
			/>
		),
	}
}
