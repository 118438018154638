import React from "react"

import { FeatureGalleryItem } from "../../../components/_V2/FeaturesGallery"
import { StaticImage } from "gatsby-plugin-image"
import { pagesLinks } from "../../../pages-data/_V2/common/links"

import mediaContent from "./mediaContent.module.scss"
import MobileAppBackdropIcon from "../../../assets/images/mobileFeatures/MobileAppBackdropIcon"
import backdropIconsStyles from "./backdropIconsStyles.module.scss"
import WebsiteBackdropIcon from "../../../assets/images/mobileFeatures/WebsiteBackdropIcon"
import { Locales } from "../../../localization/types"

export const getData = (locale: Locales) => {
	if (locale === "ru-KZ") {
		return {
			features: [
				{
					desktopContentWidth: 660,
					tabName: "Приложение",
					desktopTitle: (
						<>
							Принимай заказы
							<br />через своё приложение
						</>
					),
					tabletTitle: "Принимай заказы через своё приложение",
					mobileTitle: "Принимай \nзаказы \nчерез своё \nприложение",
					desktopContent: (
						<>
							Быстро, в&nbsp;фирменном стиле и&nbsp;без найма разработчиков. Клиенты смогут пользоваться бонусной
							программой и&nbsp;делать заказы на&nbsp;доставку.
						</>
					),
					tabletContent: (
						<>
							Запусти брендированное приложение&nbsp;&mdash; это быстро, без найма разработчиков. Гости смогут
							пользоваться бонусной программой, делать заказы на&nbsp;доставку.
						</>
					),
					mobileContent: (
						<>
							Запусти брендированное приложение&nbsp;&mdash; это быстро, без найма разработчиков. Гости смогут
							пользоваться бонусной программой, делать заказы на&nbsp;доставку.
						</>
					),
					detailLink: pagesLinks.indexWL,
					mediaContent: (
						<div className={mediaContent.image__container1}>
							<StaticImage
								className={mediaContent.image1}
								src="../../../assets/images/mobileFeatures/phone_kz.png"
								alt={"мобильное приложение для кафе"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: (
						<MobileAppBackdropIcon
							className={backdropIconsStyles.mobileAppBackdropIcon}
						/>
					),
				},
				{
					desktopContentWidth: 600,
					tabName: "Сайт",
					desktopTitle: (
						<>
							Запусти собственный<br />
							сайт с доставкой
						</>
					),
					tabletTitle: "Запусти собственный сайт с доставкой",
					mobileTitle: "Запусти \nсобственный \nсайт \nс доставкой",
					desktopContent: (
						<>
							Получай больше заказов с&nbsp;помощью сайта своего заведения. Подключение доставки с&nbsp;расчетом
							стоимости по&nbsp;зонам, опция самовывоза, всегда актуальное меню и&nbsp;различные способы оплаты.
						</>
					),
					tabletContent: (
						<>
							Получай больше заказов с&nbsp;помощью сайта своего заведения. Подключение доставки с&nbsp;расчетом
							стоимости по&nbsp;зонам, опция самовывоза, всегда актуальное меню и&nbsp;различные способы оплаты.
						</>
					),
					mobileContent: (
						<>
							Получай больше заказов с&nbsp;помощью сайта своего заведения. Подключение доставки с&nbsp;расчетом
							стоимости
							по&nbsp;зонам, опция самовывоза, всегда актуальное меню и&nbsp;различные способы оплаты.
						</>
					),
					detailLink: pagesLinks.indexWL,
					mediaContent: (
						<div className={mediaContent.image__container2}>
							<StaticImage
								className={mediaContent.image2}
								src="../../../assets/images/mobileFeatures/shop-feature_kz.webp"
								alt={"Сайт для ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: (
						<WebsiteBackdropIcon
							className={backdropIconsStyles.websiteBackdropIcon}
						/>
					),
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		}
	}

	if (locale === "kz-KZ") {
		return {
			features: [
				{
					desktopContentWidth: 660,
					tabName: "Қосымша",
					desktopTitle: (
						<>
							Тапсырыстарды өз<br />қосымшаңыз арқылы қабылдаңыз
						</>
					),
					tabletTitle: "Тапсырыстарды өз қосымшаңыз арқылы қабылдаңыз",
					mobileTitle: "Тапсырыстарды\nөз қосымшаңыз\nарқылы қабылдаңыз",
					desktopContent: (
						<>
							Брендтелген қосымшаны іске қосыңыз — бұл тез, әзірлеушілерді жалдаусыз болады.
							Қонақтар бонустық бағдарламаны пайдаланып, жеткізуге тапсырыстарды жасай алады.
						</>
					),
					detailLink: pagesLinks.indexWL,
					mediaContent: (
						<div className={mediaContent.image__container1}>
							<StaticImage
								className={mediaContent.image1}
								src="../../../assets/images/mobileFeatures/phone_kz.png"
								alt={"мобильное приложение для кафе"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: (
						<MobileAppBackdropIcon
							className={backdropIconsStyles.mobileAppBackdropIcon}
						/>
					),
				},
				{
					desktopContentWidth: 600,
					tabName: "Сайт",
					desktopTitle: (
						<>
							Жеткізу мүмкіндігі бар<br />сайтыңызды іске қосыңыз
						</>
					),
					tabletTitle: "Жеткізу мүмкіндігі бар сайтыңызды іске қосыңыз",
					mobileTitle: "Жеткізу \nмүмкіндігі бар\nсайтыңызды іске қосыңыз",
					desktopContent: (
						<>
							Мекемеңіздің сайты көмегімен көбірек тапсырыстар алыңыз. Аймақтар бойынша құнды есептеумен
							жеткізуді қосу, өзімен әкету опциясы, әрқашан өзекті мәзір және әртүрлі төлеу тәсілдері.
						</>
					),
					detailLink: pagesLinks.indexWL,
					mediaContent: (
						<div className={mediaContent.image__container2}>
							<StaticImage
								className={mediaContent.image2}
								src="../../../assets/images/mobileFeatures/shop-feature_kz.webp"
								alt={"Сайт для ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: (
						<WebsiteBackdropIcon
							className={backdropIconsStyles.websiteBackdropIcon}
						/>
					),
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		}
	}

	if (locale === "ru-BY") {
		return {
			features: [
				{
					desktopContentWidth: 660,
					tabName: "Приложение",
					desktopTitle: (
						<>
							Больше заказов
							<br />с приложением для гостей
						</>
					),
					tabletTitle: "Больше заказов \nс приложением для гостей",
					mobileTitle: "Больше заказов с приложением для гостей",
					desktopContent: (
						<>
							Быстро, в&nbsp;фирменном стиле и&nbsp;без найма разработчиков. Клиенты смогут пользоваться бонусной
							программой и&nbsp;делать заказы на&nbsp;доставку.
						</>
					),
					tabletContent: (
						<>
							Быстро, бесплатно, в&nbsp;фирменном стиле и&nbsp;без найма разработчиков. Клиенты смогут пользоваться
							бонусной программой и&nbsp;делать заказы на&nbsp;доставку.
						</>
					),
					mobileContent: (
						<>
							Быстро, бесплатно, в&nbsp;фирменном стиле и&nbsp;без найма разработчиков. Клиенты смогут пользоваться
							бонусной программой и&nbsp;делать заказы на&nbsp;доставку.
						</>
					),
					detailLink: pagesLinks.indexWL,
					mediaContent: (
						<div className={mediaContent.image__container1}>
							<StaticImage
								className={mediaContent.image1}
								src="../../../assets/images/mobileFeatures/phone_by.png"
								alt={"мобильное приложение для кафе"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: (
						<MobileAppBackdropIcon
							className={backdropIconsStyles.mobileAppBackdropIcon}
						/>
					),
				},
				{
					desktopContentWidth: 600,
					tabName: "Сайт",
					desktopTitle: (
						<>
							А ещё больше —<br />
							через собственный сайт
						</>
					),
					tabletTitle: "А ещё больше — \nчерез собственный сайт",
					mobileTitle: "А ещё больше — через собственный сайт",
					desktopContent: (
						<>
							Подключение доставки с&nbsp;расчётом стоимости по&nbsp;зонам, опция самовывоза, всегда актуальное меню
							и&nbsp;разные способы оплаты.
						</>
					),
					tabletContent: (
						<>
							Подключение доставки с&nbsp;расчётом стоимости по&nbsp;зонам, опция самовывоза, всегда актуальное меню
							и&nbsp;разные способы оплаты.
						</>
					),
					mobileContent: (
						<>
							Подключение доставки с&nbsp;расчётом стоимости по&nbsp;зонам, опция самовывоза, всегда актуальное меню
							и&nbsp;разные способы оплаты.
						</>
					),
					detailLink: pagesLinks.indexWL,
					mediaContent: (
						<div className={mediaContent.image__container2}>
							<StaticImage
								className={mediaContent.image2}
								src="../../../assets/images/mobileFeatures/shop-feature_by.png"
								alt={"Сайт для ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: (
						<WebsiteBackdropIcon
							className={backdropIconsStyles.websiteBackdropIcon}
						/>
					),
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		}
	}

	return {
		features: [
			{
				desktopContentWidth: 660,
				tabName: "Приложение",
				desktopTitle: (
					<>
						Больше заказов
						<br />с приложением для гостей
					</>
				),
				tabletTitle: "Больше заказов с приложением для гостей",
				mobileTitle: "Больше заказов с приложением для гостей",
				desktopContent: (
					<>
						Собственное приложение&nbsp;&mdash; не&nbsp;страница на&nbsp;площадке
						агрегатора. Всё в&nbsp;фирменном стиле компании, гости запоминают
						заведение и&nbsp;выбирают его снова. Клиенты могут пользоваться
						бонусной программой и&nbsp;делать заказы на&nbsp;доставку.
					</>
				),
				tabletContent: (
					<>
						Собственное приложение&nbsp;&mdash; не&nbsp;страница на&nbsp;площадке
						агрегатора. Всё в&nbsp;фирменном стиле компании, гости запоминают
						заведение и&nbsp;выбирают его снова. Клиенты могут пользоваться
						бонусной программой и&nbsp;делать заказы на&nbsp;доставку.
					</>
				),
				mobileContent: (
					<>
						Собственное приложение&nbsp;&mdash; не&nbsp;страница на&nbsp;площадке
						агрегатора. Всё в&nbsp;фирменном стиле компании, гости запоминают
						заведение и&nbsp;выбирают его снова. Клиенты могут пользоваться
						бонусной программой и&nbsp;делать заказы на&nbsp;доставку.
					</>
				),
				detailLink: pagesLinks.indexWL,
				mediaContent: (
					<div className={mediaContent.image__container1}>
						<StaticImage
							className={mediaContent.image1}
							src="../../../assets/images/mobileFeatures/phone.png"
							alt={"мобильное приложение для кафе"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					</div>
				),
				backdropContent: (
					<MobileAppBackdropIcon
						className={backdropIconsStyles.mobileAppBackdropIcon}
					/>
				),
			},
			{
				desktopContentWidth: 600,
				tabName: "Сайт",
				desktopTitle: (
					<>
						А ещё больше —<br />
						на полноценном сайте
					</>
				),
				tabletTitle: "А ещё больше — на полноценном сайте",
				mobileTitle: "А ещё больше — на полноценном сайте",
				desktopContent: (
					<>
						Получи новые возможности. Клиент ищет в&nbsp;интернете доставку пиццы
						в&nbsp;своём районе и&nbsp;находит сайт заведения.
						Подключение доставки с&nbsp;расчётом стоимости по&nbsp;зонам, опция
						самовывоза, всегда актуальное меню. Разные способы оплаты
						в&nbsp;модуле доставки: онлайн, наличными, картой, бонусами.
					</>
				),
				tabletContent: (
					<>
						Получи новые возможности. Клиент ищет в&nbsp;интернете доставку пиццы
						в&nbsp;своём районе и&nbsp;находит сайт заведения.
						Подключение доставки с&nbsp;расчётом стоимости по&nbsp;зонам, опция
						самовывоза, всегда актуальное меню. Разные способы оплаты
						в&nbsp;модуле доставки: онлайн, наличными, картой, бонусами.
					</>
				),
				mobileContent: (
					<>
						Получи новые возможности. Клиент ищет в&nbsp;интернете доставку пиццы
						в&nbsp;своём районе и&nbsp;находит сайт заведения.
						Подключение доставки с&nbsp;расчётом стоимости по&nbsp;зонам, опция
						самовывоза, всегда актуальное меню. Разные способы оплаты
						в&nbsp;модуле доставки: онлайн, наличными, картой, бонусами.
					</>
				),
				detailLink: pagesLinks.indexWL,
				mediaContent: (
					<div className={mediaContent.image__container2}>
						<StaticImage
							className={mediaContent.image2}
							src="../../../assets/images/mobileFeatures/shop-feature.webp"
							alt={"Сайт для ресторана"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					</div>
				),
				backdropContent: (
					<WebsiteBackdropIcon
						className={backdropIconsStyles.websiteBackdropIcon}
					/>
				),
			},
		],
	} as {
		features: Array<FeatureGalleryItem>
	}
}
