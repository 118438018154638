import React from "react"

import ProductPresentationBase from "../../../components/_V2/ProductPresentationBase"

import Backdrop from "./assets/Backdrop"

import { getData } from "./data"
import styles from "./styles.module.scss"
import { useLocalizationContext } from "../../../localization/useLocalizationContext"

interface Props {
	className?: string
}

export default function ProductPresentation(props: Props) {
	const localizationContext = useLocalizationContext();

	return (
		<ProductPresentationBase
			key={localizationContext.locale}
			sectionClass={styles.section}
			containerClass={styles.content}
			data={getData(localizationContext.locale)}
			backdrop={Backdrop}
			backdropClass={styles.backdrop}
			className={props.className}
		/>
	)
}
