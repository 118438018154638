import React, { useState } from "react"
import classNames from "classnames"

import TextBillboard from "../../../components/_V2/TextBillboard"
import DesignCtaForm from "../../../components/_V2/DesignCtaForm"

import styles from "./styles.module.scss"
import { getData } from "./data"
import { requestForPasskitDesignOffer } from "../../../apiRequests/callToAction"
import { useLocalizationContext } from "../../../localization/useLocalizationContext"

interface Props {
	className?: string
}

export default function UniqDesign(props: Props) {
	const localizationContext = useLocalizationContext();

	const [isModalOpen, setIsModalOpen] = useState(false);

	const handleModalStatus = () => {
		setIsModalOpen(!isModalOpen)
	}

	return (
		<div className={props.className}>
			<div className={styles.sectionWlUniqDesign}>
				<TextBillboard
					className={classNames(styles.sectionContent)}
					data={getData(localizationContext.locale)}
					action={handleModalStatus}
				/>
			</div>

			<DesignCtaForm
				isOpen={isModalOpen}
				onClose={handleModalStatus}
				request={requestForPasskitDesignOffer}
			/>
		</div>
	)
}
