import React from "react"

import { getData } from "./data"
import SimpleFeatures from "../../../components/_V2/SimpleFeatures"
import { useLocalizationContext } from "../../../localization/useLocalizationContext"

interface Props {
	className?: string
}

export default function ConsultationFeature(props: Props) {
	const localizationContext = useLocalizationContext();

	return <SimpleFeatures data={getData(localizationContext.locale)} className={props.className} />
}
