import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import { pagesLinks, pagesLinksKz } from "../../../pages-data/_V2/common/links"
import styles from "./styles.module.scss"
import { Locales } from "../../../localization/types"

export const getData = (locale: Locales) => {
	if (locale === "ru-KZ") {
		return {
			pageName: "Электронная карта лояльности",
			desktopTitle: "Карты, деньги, любимчикам скидки",
			subTitle: (
				<>
					<p className={styles.content__desktop}>
						Электронные карты лояльности в&nbsp;Apple Wallet и&nbsp;Google Pay.
						Системный подход к&nbsp;бонусным программам и&nbsp;CRM
						— на&nbsp;любом тарифе Quick Resto.

					</p>
					<p className={styles.content__tablet}>
						<noindex>
							Электронные карты лояльности для твоего бизнеса в Apple Wallet и Google Pay. Системный подход к бонусным программам <br />
							и CRM доступен на любом тарифе Quick Resto.
						</noindex>
					</p>
					<p className={styles.content__mobile}>
						<noindex>
							Электронные карты лояльности для <br />
							твоего бизнеса в Apple Wallet и Google Pay. <br />
							Системный подход к бонусным <br />
							программам и CRM доступен на любом <br />
							тарифе Quick Resto.
						</noindex>
					</p>
				</>
			),
			cta: pagesLinks.registration.text,
			ctaLink: pagesLinks.registration,
			figure: (
				<>
					<StaticImage
						className={styles.image}
						src="./assets/passkit-presentation-cards.png"
						alt="электронные карты лояльности"
						breakpoints={[337, 674, 870, 1740, 821, 1642]}
						sizes="(max-width: 500px) 337px, (max-width: 1319px) 870px, (min-width: 1320px) 600px"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
					<StaticImage
						className={styles.imageMobile}
						src="./assets/passkit-presentation-cards-mobile.png"
						alt="электронные карты лояльности"
						breakpoints={[352, 470, 1642]}
						sizes="(max-width: 500px) 352px, (min-width: 1320px) 600px"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				</>
			),
		}
	}

	if (locale === "kz-KZ") {
		return {
			pageName: "Электрондық адалдық картасы",
			desktopTitle: "Карталар, ақша, сүйікті қонақтарға жеңілдіктер",
			subTitle: (
				<>
					<p className={styles.content__desktop}>
						Бизнесіңізге арналған электрондық адалдық карталары Apple Wallet-те және Google Pay-де.
						Бонустық бағдарламаларға және CRM-ге жүйелік тәсілдеме Quick Resto кез келген тарифінде қолжетімді.

					</p>
					<p className={styles.content__tablet}>
						<noindex>
							Бизнесіңізге арналған электрондық адалдық карталары Apple Wallet-те және Google Pay-де.
							Бонустық бағдарламаларға және CRM-ге жүйелік тәсілдеме Quick Resto кез келген тарифінде қолжетімді.
						</noindex>
					</p>
					<p className={styles.content__mobile}>
						<noindex>
							Бизнесіңізге арналған электрондық адалдық карталары Apple Wallet-те және Google Pay-де.
							Бонустық бағдарламаларға және CRM-ге жүйелік тәсілдеме Quick Resto кез келген тарифінде қолжетімді.
						</noindex>
					</p>
				</>
			),
			cta: pagesLinksKz.registration.text,
			ctaLink: pagesLinks.registration,
			figure: (
				<>
					<StaticImage
						className={styles.image}
						src="./assets/passkit-presentation-cards.png"
						alt="электронные карты лояльности"
						breakpoints={[337, 674, 870, 1740, 821, 1642]}
						sizes="(max-width: 500px) 337px, (max-width: 1319px) 870px, (min-width: 1320px) 600px"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
					<StaticImage
						className={styles.imageMobile}
						src="./assets/passkit-presentation-cards-mobile.png"
						alt="электронные карты лояльности"
						breakpoints={[352, 470, 1642]}
						sizes="(max-width: 500px) 352px, (min-width: 1320px) 600px"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				</>
			),
		}
	}

	if (locale === "ru-BY") {
		return {
			pageName: "Электронная карта лояльности",
			desktopTitle: "Карты, деньги, любимчикам скидки",
			subTitle: (
				<>
					<p className={styles.content__desktop}>
						Электронные карты лояльности в&nbsp;Apple Wallet и&nbsp;Google Pay.
						Системный подход к&nbsp;бонусным программам и&nbsp;CRM
						— на&nbsp;любом тарифе Quick Resto.

					</p>
					<p className={styles.content__tablet}>
						<noindex>
							Электронные карты лояльности для твоего бизнеса в Apple Wallet и Google Pay. Системный подход к бонусным программам <br />
							и CRM доступен на любом тарифе Quick Resto.
						</noindex>
					</p>
					<p className={styles.content__mobile}>
						<noindex>
							Электронные карты лояльности для <br />
							твоего бизнеса в Apple Wallet и Google Pay. <br />
							Системный подход к бонусным <br />
							программам и CRM доступен на любом <br />
							тарифе Quick Resto.
						</noindex>
					</p>
				</>
			),
			cta: pagesLinks.registration.text,
			ctaLink: pagesLinks.registration,
			figure: (
				<>
					<StaticImage
						className={styles.image}
						src="./assets/passkit-presentation-cards.png"
						alt="электронные карты лояльности"
						breakpoints={[337, 674, 870, 1740, 821, 1642]}
						sizes="(max-width: 500px) 337px, (max-width: 1319px) 870px, (min-width: 1320px) 600px"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
					<StaticImage
						className={styles.imageMobile}
						src="./assets/passkit-presentation-cards-mobile.png"
						alt="электронные карты лояльности"
						breakpoints={[352, 470, 1642]}
						sizes="(max-width: 500px) 352px, (min-width: 1320px) 600px"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				</>
			),
		}
	}

	return {
		pageName: "Электронная карта лояльности",
		desktopTitle: "И карты в руки!",
		subTitle: (
			<>
				Электронные карты лояльности для бизнеса в&nbsp;Apple Wallet и&nbsp;Google
				Pay. Системный подход к&nbsp;бонусным программам и&nbsp;CRM доступен
				на&nbsp;любом тарифе Quick Resto.
			</>
		),
		cta: pagesLinks.registration.text,
		ctaLink: pagesLinks.registration,
		figure: (
			<>
				<StaticImage
					className={styles.image}
					src="./assets/passkit-presentation-cards.png"
					alt="электронные карты лояльности"
					breakpoints={[337, 674, 870, 1740, 821, 1642]}
					sizes="(max-width: 500px) 337px, (max-width: 1319px) 870px, (min-width: 1320px) 600px"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
				<StaticImage
					className={styles.imageMobile}
					src="./assets/passkit-presentation-cards-mobile.png"
					alt="электронные карты лояльности"
					breakpoints={[352, 470, 1642]}
					sizes="(max-width: 500px) 352px, (min-width: 1320px) 600px"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			</>
		),
	}
}
