import React from "react"
import SimpleFeatures from "../../../components/_V2/SimpleFeatures"

interface Props {
	className?: string
	data: []
}

export default function Features(props: Props) {
	return <SimpleFeatures data={props.data} className={props.className} />
}
