import React, { useEffect, useState } from "react"
import classNames from "classnames"

import styles from "./styles.module.scss"

import {useLocalizationContext} from "../../../localization/useLocalizationContext";
import CardsBonus from "../../../assets/_V2/svg/CardsBonus";
import {StaticImage} from "gatsby-plugin-image";
import ConsultationCtaFormA from "../../../components/_V2/ConsultationCtaFormA"


interface Props {
	className?: string
}

export default function SectionLoyaltyCardsFeatures(props: Props) {
	const localizationContext = useLocalizationContext();

	const pricesCards = {
		'ru-RU': 1990,
		'ru-KZ': 9990,
		'ru-BY': 70,
		'kz-KZ': 9990,
	}

	const pricesImplementationCards = {
		'ru-RU': 3990,
		'ru-KZ': 20990,
		'ru-BY': 140,
		'kz-KZ': 20990,
	}

	const currency = {
		'ru-RU': "₽",
		'ru-KZ': "₸",
		'ru-BY': "BYN",
		'kz-KZ': "₸",
	}

	const [isModalOpen, setIsModalOpen] = useState(false)

	const handleModalStatus = () => {
		setIsModalOpen(!isModalOpen)
	}

	return (
		<>
		<section className={classNames(styles.loyaltyTariffs__container, props.className)}>
			<article className={styles.loyaltyTariffs__item}>
				<div className={styles.loyaltyTariffs__top}>
					<h3 className={styles.loyaltyTariffs__title}>Лояльность.Карты</h3>
					<p className={styles.loyaltyTariffs__description}>
						Электронные карты лояльности с&nbsp;push-уведомлениями  для iOS и&nbsp;Android
					</p>
					<ul className={styles.loyaltyTariffs__features__list}>
						<div className={styles.loyaltyTariffs__features__section}>
							<li className={styles.loyaltyTariffs__features__item}>Мгновенный выпуск карт
							</li>
							<li className={styles.loyaltyTariffs__features__item}>Активное вовлечение аудитории
							</li>
						</div>
						<div className={styles.loyaltyTariffs__features__section}>
							<li className={styles.loyaltyTariffs__features__item}>Сегментация базы гостей
							</li>
							<li className={styles.loyaltyTariffs__features__item}>Кастомизация стиля
							</li>
						</div>
					</ul>
				</div>
				<div className={styles.loyaltyTariffs__bottom}>
					<div className={styles.loyaltyTariffs__footer}>
						<button
							type={"button"}
							className={styles.loyaltyTariffs__button}
							onClick={handleModalStatus}
						>

							Подключить
						</button>
						<p className={styles.loyaltyTariffs__price}>
							{pricesCards[localizationContext.locale]}
							<span className={styles.loyaltyTariffs__currency}>{currency[localizationContext.locale]}/мес</span>
						</p>
					</div>
					<p className={styles.loyaltyTariffs__paymentInfo}>Разовый платеж за подключение — {pricesImplementationCards[localizationContext.locale]} {currency[localizationContext.locale]}</p>
				</div>
			</article>
			<div className={styles.loyaltyTariffs__imagesSection}>
			<StaticImage
				className={styles.loyaltyTariffs__image}
				src="./assets/loyalty-cards-presentation-desktop.png"
				alt={"Карты лояльности"}
				objectFit={"contain"}
				placeholder={"blurred"}
				quality={90}
			/>
			</div>
			<ConsultationCtaFormA isOpen={isModalOpen} onClose={handleModalStatus} />
		</section>
		</>
	)
}
