import { Locales } from "../../../localization/types"

export const getData = (locales: Locales) => {
	if (locales === 'ru-KZ') {
		return [
			{
				title: "Мгновенный выпуск",
				description:
					"Создай карту за пару кликов. Данные гостя и информация о заказах автоматически сохраняются в CRM.",
			},
			{
				title: "Объединяй и вовлекай",
				description:
					"Сегментируй базу гостей и выбирай условия автоматического изменения статуса в программе лояльности.",
			},
			{
				title: "Индивидуальный дизайн",
				description:
					"Используй дизайн-конструктор карт и развивай единый визуальный образ своего бренда.",
			},
		]
	}

	if (locales === 'kz-KZ') {
		return [
			{
				title: "Лезде шығару",
				description:
					"Картаны екі кликпен жасаңыз. Қонақтың деректері және тапсырыстар туралы ақпарат CRM-де автоматты түрде сақталады.",
			},
			{
				title: "Біріктіріңіз және тартыңыз",
				description:
					"Қонақтар қорын сегменттеңіз және адалдық бағдарламасында мәртебені автоматты түрде өзгерту шарттарын таңдаңыз.",
			},
			{
				title: "Жеке дизайн",
				description:
					"Карталардың дизайн-конструкторын пайдаланыңыз және брендіңіздің бірыңғай визуалды бейнесін дамытыңыз.",
			},
		]
	}

	if (locales === 'ru-BY') {
		return [
			{
				title: "Мгновенный выпуск",
				description:
					"Создай карту за пару кликов. Данные гостя и информация о заказах автоматически сохраняются в CRM.",
			},
			{
				title: "Объединяй и вовлекай",
				description:
					"Сегментируй базу гостей и выбирай условия автоматического изменения статуса в программе лояльности.",
			},
			{
				title: "Индивидуальный дизайн",
				description:
					"Используй дизайн-конструктор карт и развивай единый визуальный образ своего бренда.",
			},
		]
	}

	return [
		{
			title: "Мгновенный выпуск",
			description:
				"Создай карту за пару кликов. Данные гостя и информация о заказах автоматически сохраняются в CRM.",
		},
		{
			title: "Активное вовлечение",
			description:
				"Сегментируй базу гостей и выбирай условия автоматического изменения статуса в программе лояльности.",
		},
		{
			title: "Индивидуальный дизайн",
			description:
				"Используй дизайн-конструктор карт и развивай единый визуальный образ своего бренда.",
		},
	]
}
